// Pictures Import
// p = portolio

import pImg1 from "../../assets/potfolioImages/pImg1.jpg";
import pImg2 from "../../assets/potfolioImages/pImg2.jpg";
import pImg3 from "../../assets/potfolioImages/pImg3.jpg";
import pImg4 from "../../assets/potfolioImages/pImg4.jpg";
import pImg5 from "../../assets/potfolioImages/pImg5.jpg";
import pImg6 from "../../assets/potfolioImages/pImg6.jpg";
import pImg7 from "../../assets/potfolioImages/pImg7.jpg";
import pImg8 from "../../assets/potfolioImages/pImg8.jpg";

export default [pImg1, pImg2, pImg3, pImg4, pImg5, pImg6, pImg7, pImg8];
